export default class StickyFormButtons 
{

    static instance = null;

    static load() {

        const element = document.querySelector('.button-bar');

        if (element) {
            this.instance = new this(element);
        }

    }

    constructor(buttonBarElement) {
        this.buttonBarElement = buttonBarElement;
        this.footerElement = document.querySelector('footer');
        this.buttonBarElement = document.querySelector('.button-bar');
        this.contentElement = document.querySelector('main');


        this.contentElement.addEventListener('scroll', () => {
            this.tick();
        });

        window.addEventListener('resize', () => {
            this.tick();
        }); 

        this.tick();
    }
    
    tick() {

        const footerHeight = this.footerElement.offsetHeight;
        const sideBarWidth = this.getSideBarWidth();
        
        if (this.contentElement.scrollHeight > this.contentElement.offsetHeight + this.contentElement.scrollTop + footerHeight) {
            this.buttonBarElement.classList.add('sticky');
            this.buttonBarElement.parentNode.style.paddingBottom = '100px';

            this.buttonBarElement.querySelector('.button-bar-inside').style.marginLeft = Math.round(sideBarWidth) + 'px';
            this.buttonBarElement.querySelector('.button-bar-inside').style.marginBottom = Math.round(footerHeight) + 'px';

            return;
        }

        this.buttonBarElement.classList.remove('sticky');
        this.buttonBarElement.parentNode.style.paddingBottom = '0';

        this.buttonBarElement.querySelector('.button-bar-inside').style.marginLeft = '0';
        this.buttonBarElement.querySelector('.button-bar-inside').style.marginBottom = '0';

    }

    getSideBarWidth() {

        let width = 0;

        document.querySelectorAll('.sidebar').forEach(item => {

            const rect = item.getBoundingClientRect();
            
            width += rect.width;

        });

        return width;

    }

}
