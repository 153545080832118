import Modal from "./Modal";

export default class ModalLoader
{
    static instance;

    static registry = [];
    
    static getInstance() {

        if (!this.instance) {
            this.instance = new this;
        }
        return this.instance;

    }

    static load() {
        this.getInstance();
    }

    constructor() {
        this.animationSpeed = 250;
        this.modals = [];
        this.modalsById = {};

        this.visibleModalStack = [];

        // find backdrop
        this.modalContainerElement = this.getModalContainerElement();

        // find all modals in view and add them
        document.querySelectorAll('.modal').forEach(element => {
            this.registerModal(element);
        });

        this.bindEvents();
    }

    bindEvents() {
        
        window.addEventListener('keyup', e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                this.popStack();
            }
        });

        window.addEventListener('mousedown', e => {
            if (e.target.classList.contains('modal')) {
                e.preventDefault();
                this.popStack();
            }
        });

        window.addEventListener('click', e => {

            if (e.target.hasAttribute('data-modal')) {
                e.preventDefault();
                this.get(e.target.getAttribute('data-modal')).show();
                return;
            }

            if (e.target.hasAttribute('data-open-in-modal')) {
                e.preventDefault();
                
                const url = e.target.getAttribute('href');

                if (!document.querySelector('#ajax-modal')) {
                    const modal = document.createElement('div');
                    modal.setAttribute('id', 'ajax-modal');
                    ['modal', 'fade', 'show'].forEach(className => { modal.classList.add(className); });
                    this.getModalContainerElement().appendChild(modal);
                    this.registerModal(modal);
                }

                fetch(url, {
                    method: 'GET',
                })
                .then(async response => {
                    if (await response.status !== 200) {
                        this.get('ajax-modal').hide();
                        return;
                    }

                    document.querySelector('#ajax-modal').innerHTML = await response.text();
                    this.get('ajax-modal').show();
                });

                return;
            }

            if (e.target.getAttribute('data-bs-dismiss') === 'modal') {
                e.preventDefault();

                this.popStack();
            }
        });
    }

    get(id) {
        if (id in this.modalsById) {
            return this.modalsById[id];
        }
        return null;
    }

    registerModal(element) {
        const modal = new Modal(element);

        if (element.getAttribute('id')) {
            this.modalsById[element.getAttribute('id')] = modal;
        }

        this.modals.push(modal);
        return modal;
    }

    pushStack(modal) {

        // add modal to the stack and show backdrop if it wasn't visible yet
        this.showBackdrop()
            .then(() => this.showModal(modal))
            .then(() => {
                this.visibleModalStack.push(modal);
                this.positionLayers();
            });

    }


    showBackdrop() {

        // show backdrop if needed
        return new Promise((resolve, reject) => {

            // if backdrop is already open then there is no need to open it
            if (this.getBackdropElement().classList.contains('show')) {
                return resolve();
            }

            this.getBackdropElement().classList.remove('d-none');
            this.getBackdropElement().classList.add('fade');

            setTimeout(() => {
                this.getBackdropElement().classList.add('show');
                resolve();
            }, 100);

        });

    }

    hideBackdrop() {
        
        // hide backdrop
        return new Promise((resolve, reject) => {

            if (this.visibleModalStack.length > 0) {
                return resolve();
            }

            this.getBackdropElement().classList.remove('show');

            setTimeout(() => {
                
                this.getBackdropElement().classList.add('d-none');
                this.getBackdropElement().classList.remove('fade');

                resolve();

            }, 100);

        });
    }

    showModal(modal) {
        return new Promise((resolve, reject) => {
            modal.getElement().classList.add('fade');
            modal.getElement().classList.add('d-block');
            setTimeout(() => {
                modal.getElement().classList.add('show');
                resolve();
            }, 300);
        });
    }

    hideModal(modal) {
        return new Promise((resolve, reject) => {
            modal.getElement().classList.remove('show');

            setTimeout(() => {
                modal.getElement().classList.remove('fade');
                modal.getElement().classList.remove('d-block');

                resolve();
            }, 300);

        });
    }

    positionLayers() {

        this.visibleModalStack.slice().reverse().forEach((item, index) => {
            let zIndex = 99 - index;

            if (index === 0) {
                zIndex = 101;
            }

            item.getElement().style.zIndex = zIndex;
        });

        this.getBackdropElement().style.zIndex = 100;

    }

    popStack() {
        const modal = this.visibleModalStack.pop();

        if (!modal) {
            // stack was empty;
            return;
        }
        this.positionLayers();

        this.hideModal(modal)
            .then(() => {
                return this.hideBackdrop();
            });

    }

    getBackdropElement() {
        return this.getModalContainerElement().querySelector('.modal-backdrop');
    }

    getModalContainerElement() {
        const element = document.getElementById('modal-with-backdrop');
        // @todo: if element does not exist, create it?
        return element;
    }


    getStickyBar() {
        return document.querySelector('.button-bar.sticky');
    }
}
