import axios from "axios";
import Controller from "../../Controller";

export default class MessageShowController extends Controller
{

    static interval = null;

    static load() 
    {
        this.when(['mail-campaign.message.show'], () => {

            window.addEventListener('input', e => {
                const target = e.target;

                // when selecting a receipient, update subject and preview message
                if (target.getAttribute('id') === 'recipient') {
                    this.loadPreviewForRecipient(target.value);
                    return;
                }

            });

            // on load: update subject and preview
            this.loadPreviewForRecipient(document.querySelector('#recipient').value);

            // render message send status now and on an interval
            this.interval = setInterval(() => { this.showMessageSendStatus(); }, 1000);
            this.showMessageSendStatus();

        });

    }

    static loadPreviewForRecipient(recipientJSON) 
    {
        const iframe = document.querySelector('iframe#preview')
        const url = iframe.getAttribute('data-src');
        const subjectElement = document.querySelector('#subject');

        axios.post(url, { recipientJSON })
            .then(response => {
                // update iframe
                iframe.contentDocument.body.innerHTML = response.data;

                // update subject
                if (subjectElement) {
                    const res = response.data.match(/<title>(.+)<\/title>/);
                    subjectElement.innerText = res[1];
                }
            });

    }

    static messageStatus = null;
    static updateInterval = 5;
    static currentIteration = 0;

    static showMessageSendStatus() 
    {
        const table = document.querySelector('[data-status-table]');
        if (!table) { return; }
        
        const url = table.getAttribute('data-status-table');

        // when the current status === send_now, we update more often than on the other statuses
        if (this.messageStatus !== null && this.currentIteration < this.updateInterval) {
            this.currentIteration++;
            return;
        }

        this.currentIteration = 0;

        axios.get(url).then(response => {

            if (this.messageStatus === 'send_now' && response.data.status === 'sent') {
                document.location.reload();
                return;
            }

            if (this.messageStatus !== response.data.status) {
                
                // if status changed, (re-)render the view

                table.innerHTML = response.data.view;
                table.querySelector('.alert-danger').closest('tr').classList.add('d-none');

                
                // cache status
                this.messageStatus = response.data.status;

            }

            // message is sending, update progress bar
            if (response.data.error === 0) {
                // no errors, hide message
                table.querySelector('.alert-danger').closest('tr').classList.add('d-none');
            }
            else {
                table.querySelector('.alert-danger').closest('tr').classList.remove('d-none');
            }
            

            if (response.data.status === 'send_now' || response.data.ignored + response.data.error + response.data.sent > 0) {

                this.updateInterval = 1;
                this.currentIteration = 1;

                // sending, show status bar
                table.querySelector('.progress').closest('tr').classList.remove('d-none');
    
                if (response.data.total === 0) {
                    // prevent division by 0, bail out
                    console.error('Prevented division by zero: audience is empty');
                    return;
                }

                table.querySelector('[data-status="ignored"]').style.width = Math.round(10 + 90 * response.data.ignored / response.data.total) + '%';
                table.querySelector('[data-status="error"]').style.width = Math.round(90 * response.data.error / response.data.total) + '%';
                table.querySelector('[data-status="sent"]').style.width = Math.round(90 * response.data.sent / response.data.total) + '%';
                table.querySelector('[data-update-progress-percentage]').innerText = Math.round(100 * response.data.sent / response.data.total) + '%';
                
                return;

            }

            this.updateInterval = 5;

        });

    }


}