export default class Controller 
{

    static load() 
    {

    }

    static getRouteName() {
        const el = document.querySelector('meta[name="route"]');

        if (el) {
            return el.getAttribute('content');
        }
        return null;
    }

    /**
     * When on a route, defined by routeNames, run the controller code 
     * 
     * @param {@} routeNames 
     * @param {*} callback 
     */
    static when(routeNames, callback) 
    {
        const currentRoute = this.getRouteName();
        let i = routeNames.length;
        
        if (routeNames.includes(currentRoute)) {
            return callback();
        }
    }

    static eventTargetIs(e, selector) 
    {
        if (!e.target) {
            return false;
        }
        
        return this.targetIs(e.target, selector, 5);
    }

    static targetIs(target, selector, maxDepth = 5)
    {
        if (maxDepth <= 0) {
            return false;
        }

        if (!target.parentNode) {
            return false;
        }

        if (target.matches(selector)) {
            return target;
        }

        return this.targetIs(target.parentNode, selector);


    } 


}