import ModalLoader from "../../../components/ModalLoader";
import Controller from "../../Controller";
import axios from "axios";

export default class MessageFormController extends Controller
{
    static instance = null;

    static load() {
        this.when(['mail-campaign.message.create', 'mail-campaign.message.edit'], () => {
            if (!this.instance) {
                this.instance = new this();
            }
        });
    }

    constructor() {
        super();

        this.modalLoader = ModalLoader.getInstance();

        this.textarea = document.querySelector('textarea');
        this.subject = document.querySelector('input#subject');

        this.focusField = null;

        [this.textarea, this.subject].forEach(element => element.addEventListener('focus', e => {
            this.focusField = e.target;
        }));

        window.addEventListener('keydown', (e) => {
            // disabled: went off too often
            if (false && e.code === 'KeyA' && e.ctrlKey) {
                if (Controller.eventTargetIs(e, 'body')) {
                    e.preventDefault();

                    document.querySelector('textarea').focus();
                    document.querySelector('textarea').select();
                }
            }
        });

        window.addEventListener('click', (e) => {

            let target = null;

            if (target = Controller.eventTargetIs(e, '[data-ajax-reload]')) {
                const container = target.closest('[data-ajax-loadfrom]');

                if (container) {
                    this.replaceContainer(container);
                }

                e.preventDefault();
            }

            if (target = Controller.eventTargetIs(e, '[data-inject-text]')) {
                e.preventDefault();
                this.handleInjectText(target);
                return;
            }

            if (target = Controller.eventTargetIs(e, '[data-preview-message]')) {
                e.preventDefault();
                this.handlePreviewMessage(target);
                return;
            }

        });

    }


    handleInjectText(target) {
        const textToInject = target.getAttribute('data-inject-text');

        this.modalLoader.popStack();                
        this.insertAtSelection(textToInject.replace(/\\n/g, '\n'));
    }


    handlePreviewMessage(target) 
    {
        const modal = this.getModal('preview-modal');

        modal
            .setTitle(document.getElementById('subject').value || 'No subject')
            .show();

        axios.post(target.getAttribute('data-preview-message'), {
            body: document.getElementById('body').value,
            subject: document.getElementById('subject').value
        }).then(response => {
            modal.getElement().querySelector('iframe').contentDocument.body.innerHTML = response.data;
            const matches = response.data.match(/<title>(.+)<\/title>/);

            if (matches) {
                modal.setTitle(matches[1]);
            }
        });
    }



    insertAtSelection(textToInject) 
    {
        const target = this.focusField || this.textarea;

        let start = target.selectionStart;
        let end = target.selectionEnd;
        let value = target.value;

        // IE support?
        if (target.selection) {

        }

        let selectedText = target.value.substring(start, end);
        
        textToInject = textToInject.replace(/\$CLIP/g, selectedText);

        value = value.substring(0, start) + textToInject + value.substring(end);
        end = start + textToInject.length;

        target.value = value;
        target.selectionStart = start;
        target.selectionEnd = end;

        target.focus();
    }
    
    getModal(name) {
        return ModalLoader.getInstance().get(name);
    }
}