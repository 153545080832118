import isoFormat from "../../../helpers/isoFormat";
import Controller from "../../Controller";
import Metadata from "../../../helpers/Metadata";

const formats = {
    'nl': 'dddd D MMMM  [(om ]HH:mm[)]',
    'en': 'dddd, [the] Do [of] MMMM [(at ]HH:mm[)]'
};


export default class MessageSendController extends Controller
{

    static load()
    {
        this.when(['mail-campaign.message.send.index'], () => {
            
            HandlePlusMinButtons.listenForEvents();
            HandleFlowChart.listenForEvents();
            HandleSubmitButton.listenForEvents();
        });

    }

}

class HandleFlowChart 
{

    static listenForEvents() 
    {

        this.showOrHideDates();
        this.updateFlowChart();
    
        window.addEventListener('input', (e) => {
            const target = e.target;
            
            if (target.getAttribute('name') === 'status') {

                this.showOrHideDates(true);
                this.updateFlowChart();

                return;
            }

            this.updateFlowChart();

        });

    }

    /**
     * the "date" and "time" fields are only applicable if "schedule send" was selected.
     */
    static showOrHideDates(focus) 
    {
        let state = false;
        
        state = (document.querySelector('form[mail-campaign-send] input[name="status"]:checked')?.getAttribute('value') === 'scheduled');

        document.querySelectorAll('[data-schedule]').forEach(scheduleElement => {

            if (state) {
                scheduleElement.classList.add('d-block');
                scheduleElement.classList.remove('d-none');

                if (focus) {
                    const el = document.getElementById('sent_at_date');
                    if (el) {
                        el.focus();
                    }
                }
                return;
            }

            scheduleElement.classList.add('d-none');
            scheduleElement.classList.remove('d-block');

        });

    }

    static getLocale() 
    {
        if (this.locale) {
            return this.locale;
        }
        return this.locale = Metadata.get('locale', 'nl');
    }

    static getSendDate() 
    {
        return new Date(document.querySelector('#sent_at_date').value +  ' ' + document.querySelector('#sent_at_time').value);
    }

    static getLockDate() 
    {
        const date = this.getSendDate();
        let windowSize = 2;

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(1);
        date.setMilliseconds(0);

        while (windowSize > 0) {
            date.setDate(date.getDate() - 1);

            // if the day is not a saturday or a sunday, it counts against our window dates.
            // this is to prevent the locking mechanismn from being triggered in a weekend.

            if (date.getDay() !== 6 && date.getDay() !== 0) {
                windowSize--;
            }


            if (date < new Date()) {
                // date is historic, lock now
                return null;
            }
    
        }

        return date;
    }


    static updateFlowChart() 
    {
        const state = document.querySelector('form[mail-campaign-send] input[name="status"]:checked')?.getAttribute('value');
        
        let sendDate = null;
        let lockDate = null;
        
        if (state === 'scheduled') {
            sendDate = this.getSendDate();
            lockDate = this.getLockDate();
        }

        document.querySelectorAll('[data-if="lock-not-direct"]').forEach(el => {

            if (lockDate === null) {
                el.classList.add('d-none');
                return;
            }

            el.classList.remove('d-none');

        });


        document.querySelectorAll('.steps li').forEach((step, index) => {
            
            let disabled = false;

            if (state === 'draft') {
                disabled = index >= 1;
            }

            if (state === 'lock') {
                disabled = index >= 2;
            }

            if (disabled) {
                step.classList.add('disabled');
            }
            else {
                step.classList.remove('disabled');
            }

            const msgElement = step.querySelector('[data-message]');
            if (msgElement) {
                let attribute = (sendDate === null) ? 'data-message-immediately' : 'data-message-at'; 

                if (msgElement.getAttribute('data-message') === 'lock') {
                    // look at the lock date
                    attribute = (lockDate === null) ? 'data-message-immediately' : 'data-message-at'; 
                }

                msgElement.innerText = msgElement.getAttribute(attribute).replace(/:(lock|send)/, (x, label) => {
                    if (label === 'lock') {
                        return lockDate ? isoFormat(lockDate, formats[this.getLocale()], this.getLocale()) : '';
                    }
                    return sendDate ? isoFormat(sendDate, formats[this.getLocale()], this.getLocale()) : '';
                });
            }

        });
        
    }
}



class HandlePlusMinButtons {

    static listenForEvents() 
    {

        window.addEventListener('click', e => {

            if (e.target.hasAttribute('data-date-add')) {
                e.preventDefault();
                this.handleDateAdd(e.target);
                return;
            }

            if (e.target.closest('[data-date-add]')) {
                e.preventDefault();
                this.handleDateAdd(e.target.closest('[data-date-add]'));
                return;
            }

        });

    }



    static handleDateAdd(element) 
    {

        const form = element.closest('form');
        const add = element.getAttribute('data-date-add');

        if (!form) {
            return;
        }

        // get current value
        const currentDate = this.getCurrentDate(form);


        if (add.indexOf('1d') !== -1) {
            if (add.indexOf('-') === 0) {
                currentDate.setDate(currentDate.getDate() - 1);
            }
            else {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }

        if (add.indexOf('15m') !== -1) {
            if (add.indexOf('-') === 0) {
                currentDate.setMinutes(currentDate.getMinutes() - 15);
            }
            else {
                currentDate.setMinutes(currentDate.getMinutes() + 15);
            }
        }
        
        
        this.setValues(form, currentDate);


    }

    static getCurrentDate(form) 
    {
        const date = form.querySelector('[type="date"]').value;
        const time = form.querySelector('[type="time"]').value;

        if (!date || !time) {
            return null;
        }

        const dateObject = new Date();

        dateObject.setDate(1);
        dateObject.setMonth(1);
        dateObject.setMilliseconds(0);
        dateObject.setSeconds(0);


        let tmp = date.split(/-/g);
        dateObject.setFullYear(tmp[0]);
        dateObject.setMonth(tmp[1] - 1);
        dateObject.setDate(tmp[2]);

        tmp = time.split(/:/g);
        dateObject.setHours(tmp[0]);
        dateObject.setMinutes(tmp[1]);

        return dateObject;

    }

    static setValues(form, date) 
    {

        const d = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(item => {
            item = String(item);
            if (item.length < 2) {
                return '0' + item;
            }
            return item;
        });

        form.querySelector('[type="date"]').value = d.join('-');

        const t = [date.getHours(), date.getMinutes()].map(item => {
            item = String(item);
            if (item.length < 2) {
                return '0' + item;
            }
            return item;
        });


        form.querySelector('[type="time"]').value = t.join(':');


        const event = new Event('input', { bubbles: true, cancelable: true });

        form.querySelector('[type="date"]').dispatchEvent(event);
        form.querySelector('[type="time"]').dispatchEvent(event);

    }

}


class HandleSubmitButton 
{

    static defaultLabel = null;

    static listenForEvents() 
    {
        this.changeSubmitLabel();
        
        window.addEventListener('input', (e) => {
            const target = e.target;
            
            if (target.getAttribute('name') === 'status') {

                this.changeSubmitLabel();

            }

        });
    }

    static changeSubmitLabel()
    {
        let label = document.querySelector('form[mail-campaign-send] input[name="status"]:checked')?.getAttribute('data-submit-label');
        let submitElement = document.querySelector('form[mail-campaign-send] button[type="submit"]')

        if (this.defaultLabel === null) {
            this.defaultLabel = submitElement.innerText;
        }

        submitElement.innerText = label || this.defaultLabel;
    }

}   


