import axios from "axios";

export default class AjaxEvents {

    static load() 
    {

        this.findIntervals();

        window.addEventListener('click', (e) => {

            let target = e.target;

            if (e.target.tagName !== 'A' && e.target.tagName !== 'BUTTON') {
                if (!e.closest) {
                    // probably clicked a text node, cancel
                    return;
                }
                target = e.closest('A, BUTTON');
                if (!target) {
                    return;
                }
            }

            if (target.hasAttribute('data-ajax-reload')) {
                const container = target.closest('[data-ajax-loadfrom]');
                if (container) {
                    this.replaceContainer(container);
                }

                e.preventDefault();
            }

        });

    }

    static replaceContainer(container) 
    {

        this.addLoader(container);

        axios.get(container.getAttribute('data-ajax-loadfrom')).then(response => {
            container.innerHTML = response.data;
        });

    }

    static addLoader(container) {

        const div = document.createElement('div');

        div.innerHTML = '';

        container.style.position = 'relative';
        container.appendChild(div);

        div.style.left = 0;
        div.style.top = 0;
        div.style.width = '100%';
        div.style.height = '100%';
        div.style.backgroundColor = 'rgba(255,255,255,.5)';
        div.style.position = 'absolute';
        div.style.display = 'flex';
        div.style.justifyContent = 'center';

        // const div2 = document.createElement('div');
        // div2.style.flex = '0 1';
        // div2.innerHTML= 'Please wait';
        // div2.style.textWrap = 'nowrap';
        // div2.paddingTop = '20px';
        
        // div.appendChild(div2);

    }

    static findIntervals() 
    {

        document.querySelectorAll('[data-ajax-interval]').forEach(item => {

            setInterval(() => {
                this.replaceContainer(item);
            }, item.getAttribute('data-ajax-interval') * 1000);

        });

    }
}