import MetadataModal from "./MetadataModal";
import Controller from "../../Controller";

export default class MetadataController extends Controller {

    static instance = null;

    static load() {
        this.when(['mail-campaign.audience.team.metadata.index'], () => {
            this.getInstance();
        });
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new this();
        }

        return this.instance;
    }

    constructor() {

        super();

        this.modal = new MetadataModal(document.getElementById('metadata-fields-modal'));
        this.textarea = document.querySelector('textarea');
        this.tableElement = document.querySelector('[data-meta-table]');
        this.foremanSelectElement = document.querySelector('select[name="foremanFacts"]');
        this.fieldSelectElement = document.querySelector('select[name="fieldName"]');

        this.load();
        this.renderSelects();

        window.addEventListener('click', e => {
            const target = e._target || e.target;

            if (target.hasAttribute('data-manage-fields')) {
                e.preventDefault();
                this.modal.setVariables(this.fields.slice(0));
                this.modal.setIllegalFieldNames(this.getForemanFacts())
                this.modal.promise().then(fields => {
                    if (fields) {
                        this.fields = fields;
                    }
                    this.renderSelects();
                    this.renderTable();
                    this.save();
                });
            }
        });


        window.addEventListener('input', e => {
            const target = e._target || e.target;

            if (target.getAttribute('name') === 'foremanFacts' || target.getAttribute('name') === 'fieldName' ) {
                this.renderTable();
                return;
            }

            if (target.closest('[data-meta-table]')) {
                //input
                const rowElement = target.closest('tr');

                const serverName = this.getServerName(rowElement);
                const fieldName = this.getSelectedFieldName();

                if (!this.servers[serverName]) {
                    this.servers[serverName] = {};
                }

                this.servers[serverName][fieldName] = e.target.value;

                this.renderTable();
                this.save();

            }

        });

        this.renderTable();
    }

    renderTable() {
        const tableBodyElement = this.tableElement.querySelector('tbody');

        tableBodyElement.querySelectorAll('tr').forEach(rowElement => {
            const foremanFacts = JSON.parse(rowElement.getAttribute('data-foreman-facts'));
            const selectedForemanFact = this.foremanSelectElement.value;
            const selectedFieldName = this.getSelectedFieldName();
            const selectedFieldType = this.getSelectedFieldType();

            const serverName = this.getServerName(rowElement);
            let value = this.servers[serverName] ? this.servers[serverName][selectedFieldName] : '';

            if (!value) { 
                value = '';
            }

            let effectiveValue = value;

            if (value === '') {
                // find default value

                const fieldObj = this.fields && this.fields.filter((obj) => { return obj.name === selectedFieldName; });

                if (fieldObj && fieldObj.length > 0) {
                    effectiveValue = fieldObj[0].default;
                }

                if (!effectiveValue) {
                    effectiveValue = '';
                }
            }

            rowElement.querySelector('td:nth-child(3)').innerText = foremanFacts[selectedForemanFact] || '';
            rowElement.querySelector('td:nth-child(4) input').value = value;

            if (selectedFieldName === '') {
                rowElement.querySelector('td:nth-child(4) input').setAttribute('disabled', true);
            }
            else {
                rowElement.querySelector('td:nth-child(4) input').removeAttribute('disabled');
            }

            rowElement.querySelector('td:nth-child(5)').innerText = effectiveValue.replace(/\$\{?([a-zA-Z]+)\}?/g, (fieldName) => {
                fieldName = fieldName.replace(/[${}]/g, '');
                if (foremanFacts[fieldName]) {
                    return foremanFacts[fieldName];
                }
                return '';
            });

        });
    }

    renderSelects() {
        const value = this.fieldSelectElement.value;

        this.fieldSelectElement.querySelectorAll('option').forEach(element => { element.remove(); })

        this.fields && this.fields.forEach(field => {
            const optionElement = document.createElement('option');
            optionElement.innerText = field.name;
            this.fieldSelectElement.appendChild(optionElement);
        });

        if (value) {
            this.fieldSelectElement.value = value;
        }
    }

    load() {
        const data = JSON.parse(this.textarea.value);

        this.fields = data.fields ? data.fields : [];
        this.servers = data.servers ? data.servers : {};
    }

    save() {
        const servers = {};

        // copy servers to object
        Object.keys(this.servers).forEach(server => {
            servers[server] = this.servers[server];
        });

        this.textarea.value = JSON.stringify({ 
            fields : this.fields,
            servers
        }, null, 2);
    } 


    getForemanFacts() {
        const items = [];
        this.foremanSelectElement.querySelectorAll('option').forEach(optionElement => {
            items.push(optionElement.value);
        });
        return items;
    }

    getServerName(rowElement) {
        return rowElement.getAttribute('data-server');
    }

    getSelectedFieldName()
    {
        return this.fieldSelectElement.value;
    }

    getSelectedFieldType() 
    {
        // filter fields by selected variable
        const fields = this.fields.filter(a => (a.name === this.fieldSelectElement.value));

        if (fields.length === 1) {
            return fields[0].type;
        }

        return 'text';
    }
}