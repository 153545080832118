export default class Dropdown
{
    static load() 
    {
        // a dropdown consists of a container element (.dropdown)
        // that contains both a button and an ul
        
        // both the button and the ul can have the .show class, our javascript aims to keep them the same for both elements.


        // update each dropdown to have the button state match the dropdown state
        this.updateAllDropdowns();


        window.addEventListener('click', e => {

            const target = e._target || e.target;

            if (target.closest('.dropdown,.dropup')) {

                if (target.hasAttribute('data-bs-toggle') || target.closest('[data-bs-toggle]')) {
                    e.preventDefault();

                    let dropdownElement = target.closest('.dropdown,.dropup');
                    // if (!dropdownElement) {
                    //     dropdownElement = target.closest('.dropup');
                    // }

                    if (dropdownElement.querySelector('.dropdown-menu').classList.contains('show')) {
                        this.closeAll();
                        this.updateAllDropdowns();
                        return;
                    }

                    // close all
                    this.closeAll();

                    // but this one
                    dropdownElement.querySelector('.dropdown-menu').classList.add('show');  

                    if (target.closest('.dropup')) {

                        dropdownElement.querySelector('.dropdown-menu').style.position = 'absolute';
                        dropdownElement.querySelector('.dropdown-menu').style.inset = 'auto auto 0px 0px';
                        dropdownElement.querySelector('.dropdown-menu').style.transform = 'translate3d(0px, -40px, 0px)';
                        dropdownElement.querySelector('.dropdown-menu').style.margin = '0';

                    }

                    this.updateAllDropdowns();

                    return;
                }
            }

            this.closeAll();

        });
    }


    static closeAll() 
    {

        document.querySelectorAll('.dropdown-menu.show').forEach(element => {
            element.classList.remove('show');
        });

    }

    static updateAllDropdowns() 
    {
        document.querySelectorAll('.dropdown-menu').forEach(element => {
            if (element.classList.contains('show')) {
                element.closest('.dropdown,.dropup').querySelector('button').classList.add('show');
                return;
            }
            element.closest('.dropdown,.dropup').querySelector('button').classList.remove('show');
        });
    }

}